import { useMemo } from 'react';
import { Box, TypeColors, Text } from '@bitrise/bitkit';
import useStatusPage from '@/hooks/useStatusPage';
import withQueryClientProvider from '../withQueryClientProvider/withQueryClientProvider';

export type BitriseStatusBadgeProps = {
  description: string;
  severity?: 'none' | 'critical' | 'major' | 'minor' | string;
};

export const BitriseStatusBadge = ({ description, severity }: BitriseStatusBadgeProps) => {
  const backgroundColor = useMemo<TypeColors>(() => {
    switch (severity) {
      case 'none':
        return 'green.70';
      case 'critical':
        return 'red.70';
      case 'major':
        return 'orange.70';
      case 'minor':
        return 'yellow.70';
      default:
        return 'neutral.70';
    }
  }, [severity]);

  return (
    <Box
      as="a"
      backgroundColor={backgroundColor}
      borderRadius="4"
      display="inline-block"
      href="https://status.bitrise.io/"
      padding="0.5rem 0.75rem"
      rel="noreferrer"
      target="_blank"
      textColor="neutral.10"
    >
      <Text fontWeight="bold" size="1" textTransform="uppercase">
        Status: {description}
      </Text>
    </Box>
  );
};

export const BitriseStatusContent = (): JSX.Element | null => {
  const { data: status } = useStatusPage();

  const severity = useMemo<BitriseStatusBadgeProps['severity']>(
    () => (status?.indicator === 'maintenance' ? 'minor' : status?.indicator),
    [status?.indicator],
  );

  if (!status) {
    return null;
  }
  return <BitriseStatusBadge description={status.description} severity={severity} />;
};

export default withQueryClientProvider(BitriseStatusContent);
