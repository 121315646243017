import React from 'react';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from '@/utils/query';

const withQueryClientProvider =
  <P extends object>(WrappedComponent: React.ComponentType<P>): React.ComponentType<P> =>
  (props) => (
    <QueryClientProvider client={queryClient}>
      <WrappedComponent {...props} />
    </QueryClientProvider>
  );

export default withQueryClientProvider;
