import { Box, Button, Image, Text } from '@bitrise/bitkit';
import BitbotLogoWithText from '@/components/page/PageHeader/PageHeaderContent/BitbotLogoWithText';
import InternalFooter from '@/components/InternalFooter/InternalFooter';
import bitrise500 from '../../../../../public/errors/bitrise_500.svg';

const Status500ErrorPageContent = (): JSX.Element => (
  <Box
    display="flex"
    height="100vh"
    width="100vw"
    flexDirection="column"
    justifyContent="space-between"
    textAlign="center"
    textColor="white"
    background="linear-gradient(315deg, var(--colors-purple-30), var(--colors-purple-10))"
  >
    <Box
      display="flex"
      flexDirection={{ base: 'column', desktop: 'row' }}
      alignItems="center"
      flexGrow="1"
      padding="0 5%"
      gap="3rem"
      maxWidth="100rem"
      margin="0 auto"
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="start"
        textAlign="left"
        gap="2rem"
        paddingTop={{ base: '3rem', desktop: '0' }}
        maxWidth={{ base: '100%', desktop: '40%' }}
      >
        <BitbotLogoWithText height={38} />
        <Box>
          <Text as="p" fontFamily='"Source Code Pro", monospace;' marginBottom="1rem" marginRight="1em">
            500 - INTERNAL SERVER ERROR
          </Text>

          <Text as="h2" size={{ base: '7', desktop: '8' }} marginBottom="2rem" lineHeight="1.2">
            We are sorry, a hairball got into the system
          </Text>

          <Button as="a" href="/" colorScheme="white" fontSize="3" fontWeight="normal" padding="12px 24px">
            Go to dashboard
          </Button>
        </Box>
      </Box>

      <Image src={bitrise500} alt="Bitrise - 500" paddingTop="80" />
    </Box>
    <InternalFooter bg="transparent" textColor="purple.80" variant="transparent" />
  </Box>
);

export default Status500ErrorPageContent;
