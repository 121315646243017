import axios from 'axios';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { STATUS_PAGE_ID } from '@/constants';

const REFETCH_INTERVAL_MS = 20000;

type StatusPageResponse = {
  description: string;
  indicator: string;
};

// The status page ajax call should be deprioritized compared to the whole application
const windowLoadPromise = new Promise((resolve) => {
  window.addEventListener('load', () => {
    resolve(true);
  });
});

const useStatusPage = (): UseQueryResult<StatusPageResponse | null> =>
  useQuery({
    queryKey: ['system', 'status'],

    queryFn: async () => {
      await windowLoadPromise;
      const { data } = await axios.get(`https://${STATUS_PAGE_ID}.statuspage.io/api/v2/status.json`);
      return data.status || null;
    },

    refetchInterval: REFETCH_INTERVAL_MS,
  });

export default useStatusPage;
