type ColorScheme = 'white' | 'purple';

type Props = {
  height?: string | number;
  colorScheme?: ColorScheme;
};

export default ({ height = 24, colorScheme = 'white' }: Props): JSX.Element => (
  <svg
    height={height}
    viewBox="0 0 85 24"
    color={colorScheme === 'white' ? 'white' : '#7B3BA5'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.055 1.24337C16.055 1.92998 15.4915 2.4866 14.7962 2.4866C14.101 2.4866 13.5374 1.92998 13.5374 1.24337C13.5374 0.556762 14.101 0 14.7962 0C15.4915 0 16.055 0.556762 16.055 1.24337Z"
      fill="currentColor"
    />
    <path
      d="M5.40952 14.0537C5.40952 12.9456 6.31909 12.0471 7.44126 12.0471C8.56343 12.0471 9.47301 12.9456 9.47301 14.0537H5.40952Z"
      fill="currentColor"
    />
    <path
      d="M11.5057 16.0605C11.5057 17.8552 12.9789 19.3103 14.7963 19.3103C16.6135 19.3103 18.0868 17.8552 18.0868 16.0605H11.5057Z"
      fill="currentColor"
    />
    <path
      d="M22.1651 12.0475C21.0429 12.0475 20.1333 12.946 20.1333 14.0541H24.1968C24.1968 12.946 23.2873 12.0475 22.1651 12.0475Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.0271 13.7727C28.6855 11.4818 28.1742 9.16488 27.8054 7.62488C27.3658 5.7897 25.7901 4.47799 23.8847 4.36079C22.014 4.24563 18.9187 4.10822 14.7962 4.10822C10.6735 4.10822 7.57818 4.24563 5.70779 4.36079C3.80207 4.47799 2.22644 5.7897 1.78696 7.62488C1.41816 9.16415 0.906981 11.4802 0.565118 13.7727C0.33397 15.3226 0.147138 17.1531 0.00962665 19.2132C-0.0672264 20.3612 0.318069 21.4661 1.09411 22.3243C1.87132 23.1837 2.94138 23.6865 4.10742 23.7404C6.66875 23.8588 10.6022 24 14.7962 24C18.9898 24 22.9235 23.8588 25.4849 23.7404C26.651 23.6865 27.721 23.1837 28.4981 22.3243C29.2741 21.4663 29.6594 20.3613 29.5826 19.2132C29.4452 17.1531 29.2582 15.3226 29.0271 13.7727ZM26.9827 20.9878C26.572 21.4417 26.0064 21.7074 25.3899 21.736C22.85 21.8534 18.9503 21.9932 14.796 21.9932C10.6415 21.9932 6.7419 21.8534 4.20222 21.736C3.58563 21.7074 3.01998 21.4417 2.60921 20.9876C2.19962 20.5347 1.9963 19.9513 2.03679 19.3452C2.17077 17.3385 2.35186 15.5621 2.57505 14.0649C2.90632 11.8433 3.40454 9.58787 3.76407 8.08669C3.99595 7.11785 4.82794 6.42542 5.83395 6.36362C7.67562 6.25021 10.7256 6.11498 14.796 6.11498C18.8661 6.11498 21.9162 6.25021 23.7582 6.36362C24.7642 6.42542 25.5959 7.11785 25.8279 8.08669C26.1878 9.58845 26.6858 11.8447 27.0168 14.0649C27.24 15.5621 27.4213 17.3385 27.5552 19.3452C27.5956 19.9514 27.3922 20.5348 26.9827 20.9878Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40.174 19.5834C42.3057 19.5834 44.1815 17.7307 44.1815 15.2885C44.1815 12.8464 42.3057 10.9939 40.174 10.9939C38.554 10.9939 37.7013 12.1727 37.7013 12.1727V7.62542H35.3991V19.415H37.7013V18.4044C37.7013 18.4044 38.554 19.5834 40.174 19.5834ZM39.7479 13.0148C40.8562 13.0148 41.7942 13.9412 41.7942 15.2885C41.7942 16.636 40.8562 17.5624 39.7479 17.5624C38.6394 17.5624 37.7014 16.636 37.7014 15.2885C37.7014 13.9412 38.6394 13.0148 39.7479 13.0148Z"
      fill="currentColor"
    />
    <path d="M45.9719 19.415H48.2741V11.1623H45.9719V19.415Z" fill="currentColor" />
    <path
      d="M47.0803 10.0675C46.313 10.0675 45.8014 9.56222 45.8014 8.80421C45.8014 8.04621 46.313 7.54107 47.0803 7.54107C47.9329 7.54107 48.4447 8.04621 48.4447 8.80421C48.4447 9.56222 47.9329 10.0675 47.0803 10.0675Z"
      fill="currentColor"
    />
    <path
      d="M53.646 8.72005H51.5995V10.2359C51.5995 10.9096 51.1732 11.3307 50.6615 11.3307H49.8942V13.3517H51.3438V16.7202C51.3438 18.6571 52.2816 19.5834 53.987 19.5834C54.8397 19.5834 55.5218 19.3308 55.5218 19.3308V17.3939C55.5218 17.3939 55.1807 17.5624 54.6692 17.5624C54.0722 17.5624 53.646 17.1413 53.646 16.6358V13.3517H55.3512V11.3307H53.646V8.72005Z"
      fill="currentColor"
    />
    <path
      d="M62.513 13.3516C62.513 13.3516 62.2571 13.2676 61.7457 13.2676C60.5518 13.2676 59.7844 14.0255 59.7844 15.457V19.4149H57.4822V11.1622H59.7844V12.1728C59.7844 12.1728 60.6372 11.0779 62.1719 11.0779H62.513V13.3516Z"
      fill="currentColor"
    />
    <path d="M66.1792 19.415H63.877V11.1623H66.1792V19.415Z" fill="currentColor" />
    <path
      d="M63.7067 8.80421C63.7067 9.56222 64.2183 10.0675 64.9857 10.0675C65.8384 10.0675 66.3501 9.56222 66.3501 8.80421C66.3501 8.04621 65.8384 7.54107 64.9857 7.54107C64.2183 7.54107 63.7067 8.04621 63.7067 8.80421Z"
      fill="currentColor"
    />
    <path
      d="M73.086 13.6044C73.086 13.6044 72.5744 12.9307 71.7218 12.9307C71.0397 12.9307 70.6986 13.2676 70.6986 13.6044C70.6986 14.0693 71.2955 14.2592 72.0185 14.4892C73.1285 14.8423 74.5357 15.29 74.5357 16.9729C74.5357 18.4044 73.3418 19.5834 71.2102 19.5834C68.8226 19.5834 67.7995 18.0675 67.7995 18.0675L69.0785 16.8045C69.0785 16.8045 69.7608 17.7307 70.8691 17.7307C71.6366 17.7307 71.9776 17.394 71.9776 16.9729C71.9776 16.4614 71.3485 16.2592 70.5998 16.0185C69.4994 15.6648 68.1407 15.2281 68.1407 13.6044C68.1407 12.3411 69.5049 10.9939 71.3807 10.9939C73.3418 10.9939 74.2798 12.2569 74.2798 12.2569L73.086 13.6044Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M80.333 17.6466C79.0541 17.6466 78.2865 16.8887 78.1161 16.0465H84.1701C84.1701 16.0465 84.2553 15.7097 84.2553 15.1201C84.2553 12.7623 82.4647 10.9939 80.0771 10.9939C77.6897 10.9939 75.7286 12.9307 75.7286 15.2885C75.7286 17.6466 77.6897 19.5834 80.1624 19.5834C82.4647 19.5834 83.7437 18.1517 83.7437 18.1517L82.55 16.636C82.55 16.636 81.7825 17.6466 80.333 17.6466ZM81.9533 14.4464H78.1162C78.372 13.6044 79.0542 12.9307 80.0773 12.9307C81.1005 12.9307 81.7826 13.6044 81.9533 14.4464Z"
      fill="currentColor"
    />
  </svg>
);
