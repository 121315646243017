import { Box, BoxProps, Icon, Link, Text } from '@bitrise/bitkit';
import BitriseStatus from '../BitriseStatus/BitriseStatus';

const links = [
  {
    label: 'Docs',
    url: 'https://devcenter.bitrise.io/',
  },
  {
    label: 'API',
    url: 'https://devcenter.bitrise.io/api/v0.1/',
  },
  {
    label: 'Pricing',
    url: 'https://www.bitrise.io/pricing',
  },
  {
    label: 'Security',
    url: 'https://www.bitrise.io/security_policy',
  },
  {
    label: 'Terms',
    url: 'https://www.bitrise.io/terms',
  },
  {
    label: 'Privacy',
    url: 'https://www.bitrise.io/privacy',
  },
  {
    label: 'Cookie',
    url: 'https://www.bitrise.io/cookie-policy',
  },
];

const InternalFooter = ({
  hasSidebar,
  variant = 'regular',
  ...props
}: BoxProps & { hasSidebar?: boolean; variant?: 'regular' | 'transparent' }): JSX.Element => {
  const currentYear = new Date().getFullYear();
  const paddingForIntercomWidget = '5rem';
  const textColor = variant === 'regular' ? 'neutral.50' : 'purple.80';
  return (
    <Box
      display="flex"
      id="footer"
      as="footer"
      backgroundColor="neutral.95"
      marginLeft={hasSidebar ? ['0', '16.625rem'] : undefined}
      paddingX="12"
      paddingY="16"
      flexDirection={{ base: 'column', desktop: 'row' }}
      alignItems="center"
      {...props}
    >
      <Box
        display="flex"
        paddingY={{ desktop: '0', base: '16' }}
        justifyContent={['center', 'flex-start']}
        flex={1}
        marginRight={{ desktop: 'auto', base: '0' }}
        height="32"
      >
        <BitriseStatus />
      </Box>
      <Box display="flex" justifyContent="center" flex={1} gap="8" alignItems="center">
        <Icon name="Bitbot" textColor={textColor} />
        <Text size="2" textColor={textColor}>
          © {currentYear} Bitrise Ltd.
        </Text>
      </Box>
      <Box
        display="flex"
        flex={1}
        order={{ desktop: '0', base: '-1' }}
        marginLeft={{ desktop: 'auto', base: '0' }}
        gap="8"
        paddingRight={{ desktop: paddingForIntercomWidget, base: '0' }}
        justifyContent={['center', 'flex-end']}
      >
        {links.map((link) => (
          <Link key={link.label} href={link.url} isExternal size="2" textColor={textColor}>
            {link.label}
          </Link>
        ))}
      </Box>
    </Box>
  );
};

export default InternalFooter;
